@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=VT323&display=swap');

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

.App {
  text-align: center;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-items: center;
}

.GameTitle {
  background: green;  
  font-family: 'VT323', monospace;  
  padding: 8pt;
}

.Scroller {
  font-size:16pt;
}

.GameContainer {
  display: flex;
  flex: 1;  
  flex-flow:row;
  overflow: auto;  
  background: gold;
  align-items: top;
  justify-content: center;
}

.GameScreen {
  margin-top:32pt;
  text-align: center;
  vertical-align: middle;  
  z-index: 100;
}

.GameRow {
  display: flex;
  flex-flow: row;    
}

.Letter {
  width: 32px;
  height: 32px;
  border: 1px solid black;
  padding: 8pt;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'VT323', monospace;
  font-size: 32pt;
  background-color: #cccccc;
  vertical-align: middle;
  user-select: none;
}

.Letter.Correct {
  background-color: green;
}

.Letter.WrongPlace {
  background-color: yellow;
}

.GameKeyboard {
  background: red;
  display:block;
  padding: 16pt;
}

.GameKeyboardRow {
  display: flex;
  justify-content: center;
  align-content: center;  
}

.GameKeyboardKey {
  display:flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;  
  border: 1px solid black;
  font-family: 'VT323', monospace;
  padding: 8pt;
  margin: 2px;
  font-size: 16pt;
  cursor: pointer;
  user-select: none;
}

.GameFooter {
  background: magenta;
  min-height: 60px;
  font-family: 'VT323', monospace;
  vertical-align: bottom;
  font-size: 16pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Gameover {
  font-family: 'VT323', monospace;
  background-color: brown;
  color:black;
  vertical-align: bottom;
  font-size: 32pt;
  padding: 8pt;
  margin: 8pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Gameover.Victory {
  background-color: magenta;
  font-family: 'VT323', monospace;
}

